import React from 'react'
import { useConnect } from 'redux-bundler-hook'

import {
  Typography,
  Button
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import Loading from '~/UI/Shared/utils'

import { TextField } from '~/UI/Shared/Form/FormDialog'

const AVAILABLE_COMMANDS = [
  'get/set sample_interval',
  'get sensor_status',
  'get ver',
  'get batt',
  'show',
  'update',
  'get/set role',
  'reset',
  'get pv',
  'ds status',
  'ds enable',
  'ds disable',
  'get hw_sn',
  'get data',
]

const DeviceCommandsComponent = ({ deviceNetwork, deviceNode }) => {
  const {
    deviceEvents,
    deviceEventsIsLoading,
    doDeviceCommandPost,
  } = useConnect(
    'selectDeviceEvents',
    'selectDeviceEventsIsLoading',
    'doDeviceEventsSetPage',
    'doDeviceCommandPost'
  )

  const onSubmit = async (values, { setErrors }) => {
    const result = await doDeviceCommandPost({
      networkAddress: Number(deviceNetwork),
      nodeAddress: deviceNode,
      command: values.command
    })
    if (result?.error) {
      setErrors(result.error.response)
    }
  }

  const initialValues = {
    command: ''
  }

  const validationSchema = yup.object().shape({
    command: yup.string().required(),
  })

  if (!deviceEvents && deviceEventsIsLoading) {
    return <Loading />
  }

  return (
    <div style={{ position: 'relative', width: '100%', marginTop: '50px' }}>
      <Formik
        enableReinitialize
        initialErrors=""
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validationSchema={validationSchema}
      >
        {() => (
          <Form style={{ width: '50%', display: 'flex', margin: '0 auto' }}>
            <Field
              component={TextField}
              label="Command"
              name="command"
              style={{ flex: 3 }}
            />
            <Button type="submit" color="primary" style={{ flex: 1 }}>
              Send Command
            </Button>
          </Form>
        )}
      </Formik>
      <div>
        <Typography variant="h4" style={{ textDecoration: 'underline', paddingTop: '3%' }}>
          Command List
        </Typography>
        {AVAILABLE_COMMANDS.map(command => <Typography key={command} variant="h6">{command}</Typography>)}
      </div>
    </div>
  )
}

export default DeviceCommandsComponent
